@font-face {
    font-family: 'Nakheel';
    src: local('Nakheel'), url(./static/fonts/Nakheel.otf) format('otf');
}

.App {
    font-family: Nakheel, sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
::-webkit-scrollbar-thumb {
    background: #a7a7a7;
    border-radius: 3px;
}