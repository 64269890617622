.main {

    .header {

        .header-container {

            .header-right {
                
                .header-dropdown {

                    .header-dropdown-button:hover {
                        // background-color: rgb(223, 223, 223);
                        background-color: rgba(124, 60, 0, 0.1)
                    }

                    .header-dropdown-list {
                        .header-dropdown-list-element:hover {
                            // background-color: rgb(249, 249, 249);
                        }
                    }
                }
            }
        }
    }

    .card {

        .card-container {

            .card-exit:hover {
                img {
                    transform: scale(1.1) ;
                }
            }

            .card-bottom {
                .card-bottom-button:hover {
                    background-color: rgba(201, 185, 142, 0.26);

                    .card-bottom-button-inner {
                        img {
                            transform: translate(2px);
                        }
                    }
                }
            }
        }
    }

    .window-card {
        .window-card-container {

            .window-card-cross:hover {
                transform: scale(1.1);
            }

            .window-card-gallery-arrow:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}